@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'KG Red Hands';
    src: url('./assets/fonts/KGRedHands.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  .kg-red-hands {
    font-family: 'KG Red Hands', sans-serif;
  }
  
  
  
  @import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');
  
  .roboto-mono-100 {
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 100;
    font-style: normal;
  }
  
  .roboto-mono-200 {
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
  }
  
  .roboto-mono-300 {
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
  }
  
  .roboto-mono-400 {
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
  
  .roboto-mono-500 {
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }
  
  .roboto-mono-600 {
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
  }
  
  .roboto-mono-700 {
    font-family: "Roboto Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 700;
    font-style: normal;
  }
  
  
  html {
    scroll-behavior: smooth;
  }
  
  
  .nav-stats:active{
      background-color: transparent !important;
      color: white !important;
  }
  .icon-hover {
      transition: all 0.3s cubic-bezier(0.19, 1, 0.22, 1);
    }
    
    .icon-hover:hover {
      transform: scale(1.2) rotate(-20deg);
    }
  
  
    
    .nav-item::after {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      background-color: black;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: width 0.4s ease; /* Smooth animation */
      transform-origin: center; /* Start the line expansion from the center */
    }
    
    .nav-item:hover::after {
      width: 100%; /* Expand the line to full width from the center */
    }
    
    .nav-item:hover {
      color: #555; /* Optional: change text color on hover */
    }
    .object-shadow{
      box-shadow: 6px 9px 5px rgba(0, 0, 0, 0.25);
    }